/**
 * @module Wordpress Legal Logic
 * @description
 */
import { createRoot } from 'react-dom/client';
import Annotation from '../components/Annotation';

/**
 * @function filterLegal
 * @description Filter out the legal we don't need
 */
export const filterLegal = ( node, disclosures ) => {
  const legal = new Set();

  if ( ! disclosures ) {
    return [];
  }

  const els = node.querySelectorAll( '[data-legal]' );

  els.forEach( ( el ) => {
    const hash = el.getAttribute( 'data-legal' );
    const matched = disclosures.filter( ( disclosure ) => disclosure.hash === hash )[ 0 ];

    if ( ! matched ) {
      return;
    }

    legal.add( matched );
    el.setAttribute( 'data-legal-set', false );
  } );

  return {
    legal,
    legalNodes: els
  };
};

/**
 * @function setAnnotationIndex
 * @description Set an index starting at 1 to match legal to footer
 */
export const setAnnotationIndex = ( legal = new Set(), setLegal ) => {
  let annotationIndex = 1;

  legal.forEach( ( disclosure ) => {
    legal.forEach( ( disclosure2 ) => {
      if ( disclosure !== disclosure2 ) {
        if ( disclosure.full === disclosure2.full ) {
          if ( disclosure.alternateHash ) {
            disclosure.alternateHash.push( disclosure2.hash );
          } else {
            disclosure.alternateHash = [ disclosure2.hash ];
          }
          legal.delete( disclosure2 );
        }
      }
    } );
  } );

  legal.forEach( ( item ) => {
    item.annotationIndex = annotationIndex;
    annotationIndex += 1;
  } );

  setLegal( legal );
};

/**
 * @function insertAnnotations
 * @description Place annotations within their respective DOM nodes.
 */
export const insertAnnotations = ( disclosures, nodes = [] ) => {
  nodes.forEach( ( node ) => {
    const isSet = node.getAttribute( 'data-legal-set' );

    if ( isSet === 'true' ) {
      return;
    }

    const hash = node.getAttribute( 'data-legal' );
    const content = node.textContent;

    const root = createRoot( node );
    root.render( <Annotation hash={ hash } content={ content } disclosures={ disclosures }/> );
    node.setAttribute( 'data-legal-set', true );
  } );
};
